import React from "react"
import { Link, navigate } from "gatsby"
import { ReactSVG } from "react-svg"
import parse from "html-react-parser"

import Image from "@common/Image/Image"
import aboutProject from "@images/vacancy/vacancy-about-project.svg"
import whatYouWillDo from "@images/vacancy/vacancy-what-you-will-do.svg"
import whatWeExpect from "@images/vacancy/vacancy-what-we-expect.svg"
import asAPlus from "@images/vacancy/vacancy-as-a-plus.svg"
import arrowBack from "@images/common/ic-arrow-back.svg"
import BenefitsSection from "../Careers/components/BenefitsSection/BenefitsSection"
import SecondCatchUpSection from "../Common/CommonSections/SecondCatchUpSection/SecondCatchUpSection"

import "./Vacancy.scss"

const Vacancy = ({ vacancy }) => {
  const applyVacancy = () =>
    navigate("/careers/contact-us-cv", {
      state: { vacancyId: vacancy.id },
    })

  const vacancyContent = vacancy.custom_fields.reduce((acc, field) => {
    acc[field.internal_name] = field.value
    return acc
  }, {})

  return (
    <div className="vacancy-page">
      <Link to="/careers">
        <div className="vacancy-page__back-to-careers">
          <ReactSVG
            src={arrowBack}
            className="vacancy-page__back-to-careers-icon"
            alt="back to careers"
          />
          <span>Back to careers</span>
        </div>
      </Link>
      <h1>{vacancy.title}</h1>
      <div className="vacancy-page__vacancy-info">
        {vacancyContent.description && (
          <div className="vacancy-page__info-section">
            <div className="vacancy-page__info-section-title">
              <Image src={aboutProject} alt="title image" />
              <h2>About the project</h2>
            </div>
            <pre>{parse(String(vacancyContent.description))}</pre>
          </div>
        )}
        <div className="vacancy-page__info-section-wrapper">
          {vacancyContent.responsibility && (
            <div className="vacancy-page__info-section">
              <div className="vacancy-page__info-section-title">
                <Image src={whatYouWillDo} alt="responsibility image" />
                <h2>What you will do</h2>
              </div>
              <pre>{parse(String(vacancyContent.responsibility))}</pre>
            </div>
          )}
          {vacancyContent.demand && (
            <div className="vacancy-page__info-section">
              <div className="vacancy-page__info-section-title">
                <Image src={whatWeExpect} alt="demand image" />
                <h2>What we expect</h2>
              </div>
              <pre>{parse(String(vacancyContent.demand))}</pre>
            </div>
          )}
        </div>

        {vacancyContent.as_a_plus && (
          <div className="vacancy-page__info-section">
            <div className="vacancy-page__info-section-title">
              <Image src={asAPlus} alt="addition image" />
              <h2>As a plus</h2>
            </div>

            <pre>{parse(String(vacancyContent.as_a_plus))}</pre>
          </div>
        )}
      </div>
      <div className="vacancy-page__catchup-section">
        <SecondCatchUpSection
          isAnimated={false}
          title="Ready to join the team?"
          buttonText="Apply"
          handleClick={applyVacancy}
        />
      </div>
      <BenefitsSection />
    </div>
  )
}

export default Vacancy
